.MuiPaper-root {
  border-radius: 4px;
  margin-bottom: 40px;

  .MuiAlert-icon {
    align-items: center;
  }
}

.MuiAlert-message {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}