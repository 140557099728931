@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-datepicker {
  & &__input--error:not(:disabled) {
    border-color: $color-danger;
  }

  & &__error {
    margin-top: 4px;
    color: $color-danger;

    font: {
      size: 14px;
    }
  }

  & &__input {
    height: 40px;
    padding: 0 16px;
    border: 1px solid #d6d6d6;
    background-color: #ffffff;
    transition: all 0.2s ease-in-out;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 100%;
  }

  .react-datepicker-popper {
    transition: all 0.2s ease-in-out;
    opacity: 1;
  }

  &--calendar-icon {
    position: absolute;
    right: 16px;
    top: 12px;
    cursor: pointer;
    color: $color-grey-600;
    font-size: 16px;
    font-weight: 700;
    transform: translateY(-1px);
  }
}
