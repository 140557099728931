@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-sidebar {
  &__toggle-icon {
    position: absolute;
    right: -14px;
    top: 16px;
    height: 28px;
    width: 28px;
    background-color: white;
    box-shadow: 0px 0px 24px rgba(159, 153, 153, 0.5);
    border-radius: 50%;
    cursor: pointer;
    transition: 0.4s;
  }

  &.ps-sidebar-root:not(.ps-toggled):not(.ps-broken) {
    .cmp-sidebar__toggle-icon {
      display: none;
    }
  }

  &.ps-collapsed {
    .cmp-sidebar__toggle-icon {
      transform: rotateY(180deg);
    }
    .ps-sidebar-container {
      padding: 48px 0 !important;
      position: relative;
      overflow: unset;
      max-height: 100vh;
    }
  }

  &.ps-sidebar-root {
    position: fixed;
    z-index: 120;
    top: 0;
    left: -$sidebar-width;
    width: $sidebar-width;
    transition: 0.4s;
    box-shadow: 0px 0px 24px rgba(159, 153, 153, 0.15);
    height: 100%;

    .ps-sidebar-container {
      padding: 48px 16px 48px 0;
      position: relative;
      overflow: unset;
      max-height: 100vh;
    }

    .ps-submenu-content {
      background-color: white;
      border-radius: 8px;
    }

    .ps-menu-icon,
    .ps-menu-label {
      color: $color-black;
      font-weight: 500;
      font-size: 16px;
      &:hover,
      &.ps-active {
        color: $primary;
      }
    }

    .ps-menu-icon {
      color: $color-grey-300;
      display: block;
      padding: 4px;
      .title-icon > img {
        width: 120px;
      }
    }

    .ps-submenu-expand-icon {
      display: none;
    }

    .ps-menu-button {
      border-top-right-radius: 80px;
      border-bottom-right-radius: 80px;
      font-size: 16px;
      margin-bottom: 8px;
      margin-top: 8px;

      &:hover {
        background-color: $color-white;
      }

      &.ps-active {
        background-color: $color-white;
        border-left: 4px solid $primary;
      }

      &.ps-active {
        .ps-menu-icon {
          margin-left: -4px;
        }
      }
    }

    &__footer {
      padding: 8px;
      text-align: left;
      font-size: 12px;
      cursor: pointer;

      @include hover {
        filter: brightness(130%);
      }

      &__image {
        margin: 16px auto;
        align-items: center;
        color: white;
      }
    }
  }

  &.ps-sidebar-root.ps-broken {
    position: absolute;
    left: -$sidebar-width;

    &:not(.ps-toggled) {
      .cmp-sidebar__toggle-icon {
        transform: rotateY(180deg);
        right: -24px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &.ps-toggled {
    left: 0;
  }

  &.ps-collapsed {
    .ps-menu-label {
      width: 0;
      opacity: 0;
    }

    .ps-menu-icon {
      // margin-left: 5px;
      margin: 0 auto;
      margin-right: 12px;
    }

    .ps-submenu-content {
      .ps-menu-label {
        width: inherit;
        opacity: inherit;
      }

      width: 264px;
    }
  }

  &__image {
    object-fit: contain;
    cursor: pointer;
  }
}
