@media print {
  /* Set the page size to A4 */
  @page {
    size: A4;
    margin-top: 5mm;
    margin-bottom: 5mm;
    /* Adjust margins as needed */
  }

  /* Header styles */
  header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 2cm;
    /* Adjust header height as needed */
    background-color: #f0f0f0;
    /* Header background color */
    text-align: center;
    /* Center the content in the header */
    line-height: 2cm;
    /* Center vertically */
  }

  /* Footer styles */
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2cm;
    /* Adjust footer height as needed */
    background-color: #f0f0f0;
    /* Footer background color */
    text-align: center;
    /* Center the content in the footer */
    line-height: 2cm;
    /* Center vertically */
  }

  /* Content styles */
  body {
    margin-top: 2cm;

    /* Adjust content top margin to match header height */
    margin-bottom: 2cm;
    /* Adjust content bottom margin to match footer height */
  }

  @page :first {
    margin-top: 0;
  }
}
