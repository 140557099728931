@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.cmp-file-upload {
  .cmp-file-upload {
    &__disabled {
      user-select: none;
      cursor: context-menu;
      border: 1px dashed $color-light-black !important;
    }

    &__body {
      border: 1px dashed $color-primary;
      border-radius: 8px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include hover;

      @include focus {
        border-color: $color-primary;
      }

      &__label {
        color: $color-black-300;

        &__browse {
          color: $color-primary-base-500;
        }

        &__disabled {
          color: $color-light-black;
        }
      }
    }

    &__input {
      display: none;
    }

    &__error {
      margin-top: 4px;
      color: $color-danger !important;
      border-color: $color-danger !important;
      font: {
        size: 14px;
      }
    }

    &__actions {
      margin-left: 10px;
    }
  }
}
