@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-dialog {
  margin: 0 !important;
  padding: 0;
  border-radius: 16px !important;

  @include mobileScreen {
    width: calc(96%) !important;
    margin: 4px !important;
  }

  @include mobileScreen {
    margin: 0px;
  }

  &__close-icon {
    position: absolute !important;
    width: fit-content;
    top: 8px;
    right: 8px;
    z-index: 12;
  }

  &__title {
    // border-bottom: 1px solid #ebebeb;
    padding-bottom: 0px;
    width: 100%;

    &-danger {
      background-color: $color-info-base;
      color: $color-info-bg;
    }

    &-text {
      color: $color-primary-900;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0.05px;
    }

    @include mobileScreen {
      padding: 16px 16px 0 16px;
    }
  }

  &__content {
    color: $color-primary-900;
    min-height: auto !important;

    @include mobileScreen {
      padding: 8px 16px;
    }
  }

  &__footer {
    padding: 16px 24px !important;
    width: 100%;

    @include mobileScreen {
      padding: 16px 16px !important;
    }

    // dialog with border top of footer
    // &--border {
    //   padding: 16px 24px !important;
    //   border-top: 1px solid #ebebeb;

    //   @include mobileScreen {
    //     padding: 16px !important;
    //   }
    // }
  }

  &__content--visible {
    overflow: visible;
  }
}