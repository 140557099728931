@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-view-item {
  &__label {
    font-weight: 500;
    color: $color-primary-600;
    font-size: 14px;
  }
  &__value {
    font-size: $body-size;
  }

  &__empty {
    @include mobileScreen {
      display: none;
    }
  }
}
