@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;
$radio-size: 16px;

// .cmp-radio .cmp-radio__input:checked + .cmp-radio__label:before {
//   border: 1px solid darken($color-white, 25%) !important;
//   box-shadow: inset 0 0 0 3px $color-white !important;
// }

.cmp-radio {
  position: relative;
  margin-top: 20px;

  & &__input {
    position: absolute;
    opacity: 0;

    &:checked {
      + .cmp-radio__label:before {
        border: 1px solid darken($color-white, 25%) !important;
        box-shadow: inset 0 0 0 3px $color-white !important;
        background-color: $color-primary;
      }
    }

    + .cmp-radio__label {
      cursor: pointer;
      position: relative;
      display: inline-flex;
      align-items: center;
      padding-left: $radio-size + 8px;
      text-align: left;

      &:before {
        content: '';
        border-radius: 100%;
        border: 1px solid darken($color-white, 25%);
        display: inline-block;
        width: $radio-size;
        height: $radio-size;
        transition: all 250ms ease;
        position: absolute;
        left: 0;
      }
    }

    &:focus {
      + .cmp-radio__label {
        &:before {
          outline: none;
          border-color: $color-primary;
        }
      }
    }

    &:disabled {
      + .cmp-radio__label {
        &:before {
          border: 8px solid darken($color-white, 25%);
          box-shadow: inset 0 0 0 4px $color-white;
          border-color: darken($color-white, 25%);
          background-color: $color-primary;
        }
      }
      &:checked {
        + .cmp-radio__label {
          &:before {
            opacity: 0.6;
          }
        }
      }
    }

    + .cmp-radio__label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.cmp-radio__label {
  font: {
    size: 14px;
    weight: $font-weight-regular;
  }
}

.cmp-radio-groups {
  text-align: left;

  & &__column {
    display: inline-flex;
  }
}
