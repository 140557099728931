@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.form-element {
  width: 100%;
  text-align: left;

  label {
    margin-bottom: 4px;
  }

  &__error-border {
    border: 1px solid $danger;
    padding: 4px;
  }
}
