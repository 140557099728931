@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.tag {
  border-radius: 16px !important;
  font-weight: 450;
  font-size: 14px !important;
  padding: 0 12px !important;

  &.is-info.is-light {
    background-color: $color-info-bg !important;
    color: $color-info !important;
    border: 1px solid $color-info !important;
  }
  &.is-danger.is-light {
    background-color: $color-danger-bg !important;
    color: $color-danger !important;
    // border: 1px solid $color-danger !important;
  }
  &.is-warning.is-light {
    background-color: #fff3c8 !important;
    color: #f5a839 !important;
  }
  &.is-success.is-light {
    background-color: $color-success-bg !important;
    color: $color-success !important;
    // border: 1px solid $color-success !important;
  }

  &.is-white.is-light {
    background-color: $color-disabled-bg !important;
    color: $color-disabled !important;
    border: 1px solid $color-disabled !important;
  }
}
