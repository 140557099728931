@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-date-range-picker {
  &--calendar-icon {
    position: absolute;
    right: 16px;
    top: 12px;
    cursor: pointer;
    color: $color-grey-600;
    font-size: 16px;
    font-weight: 700;
    transform: translateY(-1px);
  }
}
