@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.ctn-navbar {
  &__toolbar {
    width: 100%;
  }

  &__text {
    color: $color-primary-700 !important;
  }

  &__avatar {
    width: 44px;
    height: 44px;
  }
}
