@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.cmp-table-basic {
  position: relative;

  .MuiTableCell-footer {
    border-bottom: unset !important;
    padding: 0;
  }

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}