@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: $color-white;

  display: flex;
  justify-content: center;
  align-items: center;

  &__image {
    height: 80px;
    object-fit: contain;
    animation: fadeIn 10s infinite;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      opacity: 1;
      transform: scale(2);
    }
    90% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
    }
  }
}
