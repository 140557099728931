@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-link {
  display: inline-flex;
  color: $color-link;
  font-size: 1rem;

  @include hover {
    text-decoration: underline;
    color: $color-link;
  }
}
