@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-select {
  text-align: left;
  height: $input-normal;
  border-radius: 16px !important;

  .cmp-select {
    &__indicator-separator {
      // align-self: stretch;
      width: 1px;
      background-color: #91979e;
      margin-bottom: 8px;
      margin-top: 8px;
      box-sizing: border-box;
    }
    &__control {
      min-height: $input-normal !important;
      max-height: 120px;
      overflow: auto;
      border: $default-border;
      background-color: $color-white;
      transition: $default-transition;
      border-radius: $input-border-radius;
      font-size: $body-size;

      &--is-disabled {
        background-color: $color-input-disabled;
        border: 1px solid $color-border;
        border-radius: 8px;
      }

      // &:hover {
      //   border-color: $color-border;
      // }

      &--is-focused {
        // border-color: $color-border;
        box-shadow: none;
        border: $default-border-primary;
      }
    }

    &__placeholder {
      color: #707070 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__value-container {
      padding: 0 10px;
    }
    &__indicators {
      align-self: stretch;
    }

    &__clear-indicator {
      // display: none !important;
    }

    &__menu {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      border: $default-border;
    }

    &__option {
      font-size: 14px;
      padding-left: 16px;
      padding-right: 16px;
      &--is-selected {
        color: $color-white;
        background-color: $color-primary-600;
        font-weight: 600;
        border: $default-border;
        &::after {
          content: '';
        }
      }
    }

    &__single-value {
      &--is-disabled {
        color: $color-black-500;
      }
    }
  }

  &--error {
    .cmp-select__control {
      border-color: $color-danger;

      &:hover {
        border-color: $color-danger;
      }

      &--is-focused {
        border-color: $color-danger;
        // box-shadow: $error-shadow;
      }
    }
  }

  &__menu-portal {
    min-width: 200px;
    z-index: 5000 !important;
  }
}
