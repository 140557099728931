@use '~src/scss/_vars' as *;

.form-navbar {
  background-color: white;
  border-bottom: 1px solid $nav-bottom-border-color;
}

.form-header {
  padding: 32px 40px 16px 40px;
  background-color: white;
}

.export-btn {
  background-color: $grayscale-100;
  color: $grayscale-900;
  height: 40px;
  font-size: 16px;
  &:disabled {
    cursor: not-allowed;
    background-color: $color-disabled;
    color: $grayscale-100;
  }
}

.medication-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-top {
  background-color: $color-primary-base-500;
  padding: 16px 40px;
}

.form-top-title {
  font-size: 20px;
  color: white;
  font-weight: 500;
}

.export-form-header {
  border-bottom: 1px solid $nav-bottom-border-color;
}
