@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-input-mask-select {
  display: flex;
  flex-direction: row;
  border: $default-border;
  border-radius: $input-border-radius;
  height: $input-small;
  width: 100%;

  &--error {
    border-color: $color-danger;
  }

  &__select {
    height: $input-small;
    border: none !important;
  }

  &__input {
    padding: $input-padding;
    transition: $default-transition;
    font-size: $body-size;
    border: none !important;
    border-radius: $input-border-radius;
    width: 100%;
    height: 38px !important;
  }
}

.input-mask-select {
  &__input {
    @include focus {
      outline: none !important;
      border: none !important;
    }
  }
}
