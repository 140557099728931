@use '~src/scss/vars' as *;

.cmp-dropdown {
  display: flex;

  &--body {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    >.label {
      margin-bottom: 0 !important;
    }

    .arrow-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 18px;
      width: 18px;
      position: relative;
      border: 1px solid $color-border;
      border-radius: 4px;

      >.arrow-down {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $color-placeholder;
      }

      >.hidden {
        display: none;
      }

      >.dropItems {
        position: absolute;
        top: 25px;
        left: 0;
        min-width: 200px;
        border: 1px solid $color-border;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        z-index: 1000;
        max-height: 500px;
        overflow: auto;
        cursor: pointer;

        >.item {
          background-color: $color-white;
          padding: 8px 12px;
        }

        >.active,
        .item:hover {
          background-color: $color-hover;
          color: $color-primary;
        }
      }
    }
  }
}