@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.actions-menu-item {
  padding: 12px 16px !important;

  // &:not(:last-child) {
  //   border-bottom: 1px solid $color-border;
  // }
}

.ActionsButtonPopover {
  &__paper {
    padding-left: 0 !important;
    padding-right: 0 !important;
    min-width: fit-content !important;
    background-color: white;
  }
}